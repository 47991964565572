import React from "react";
//import MyAccountBanner from "./components/MyAccountBanner";
import LoginApp from "./login/LoginApp"
import './login/LoginApp.css';
import './login/style.css';

export default function MyAccount() {
  return (
    <>
      <LoginApp />
    </>
  );
}
// render() {
  
// }