import React from "react";
import "./CarouselLoader.css";

export const CarouselLoader = () => {
  return (
    <div className="popcorn">
      <svg height={512} viewBox="0 0 498.344 498.344" width={512}>
        <path
          d="M401.226 139.5c6.72-6.479 10.908-15.567 10.908-25.639 0-18.98-14.846-34.487-33.558-35.555.229-1.259.367-2.551.367-3.877 0-11.886-9.636-21.522-21.522-21.522-.476 0-.942.041-1.411.071-1.824-13.309-13.232-23.564-27.042-23.564-13.942 0-25.436 10.453-27.09 23.948-.091.738-.789 1.231-1.527 1.138a35.13 35.13 0 00-4.426-.283c-.145 0-.287.009-.432.011.276-2.07.432-4.178.432-6.323 0-26.214-21.25-47.464-47.464-47.464-25.357 0-46.067 19.885-47.394 44.911-5.417-13.069-18.579-22.115-33.763-21.404-17.734.831-32.169 15.264-33.018 32.997a34.623 34.623 0 003.098 16.119 14.862 14.862 0 00-5.753-1.153c-8.235 0-14.911 6.676-14.911 14.911 0 .144.018.284.022.428a27.144 27.144 0 00-4.768-.428c-14.934 0-27.04 12.106-27.04 27.04 0 11.081 6.668 20.6 16.209 24.776l35.831 86.174h225.572z"
          fill="#fcd89f"
        />
        <path
          d="M401.226 139.5c6.72-6.479 10.908-15.567 10.908-25.639 0-18.98-14.846-34.487-33.558-35.555.229-1.259.367-2.551.367-3.877 0-11.886-9.636-21.522-21.522-21.522-.476 0-.942.041-1.411.071-1.824-13.309-13.232-23.564-27.042-23.564-13.942 0-25.436 10.453-27.09 23.948-.091.738-.789 1.231-1.527 1.138a35.13 35.13 0 00-4.426-.283c-.145 0-.287.009-.432.011.276-2.07.432-4.178.432-6.323 0-26.214-21.25-47.464-47.464-47.464-25.357 0-46.067 19.885-47.394 44.911-5.417-13.069-18.579-22.115-33.763-21.404-17.734.831-32.169 15.264-33.018 32.997a34.623 34.623 0 003.098 16.119 14.862 14.862 0 00-5.753-1.153c-8.235 0-14.911 6.676-14.911 14.911 0 .144.018.284.022.428a27.144 27.144 0 00-4.768-.428c-14.934 0-27.04 12.106-27.04 27.04 0 11.081 6.668 20.6 16.209 24.776l35.831 86.174h225.572z"
          fill="#fcd89f"
        />
        <path
          d="M94.715 134.116c0-14.934 12.106-27.04 27.04-27.04.626 0 1.247.022 1.862.066a4.379 4.379 0 004.191-2.32c2.319-4.398 7.533-12.656 13.605-12.656.34 0 .677.011 1.011.034 1.992.134 3.493-1.824 2.943-3.743a34.724 34.724 0 01-1.3-11.256c.849-17.733 15.283-32.166 33.016-32.998 10.772-.506 20.526 3.9 27.219 11.168 2.721 2.955 7.556 1.908 8.799-1.912 6.188-19.018 24.059-32.764 45.141-32.764 14.752 0 27.939 6.729 36.645 17.286C290.323 16.531 271.271.44 248.461.44c-25.357 0-46.067 19.885-47.394 44.911-5.417-13.069-18.579-22.116-33.765-21.404-17.734.832-32.168 15.265-33.017 32.998a34.631 34.631 0 003.098 16.118 14.862 14.862 0 00-5.753-1.153c-8.235 0-14.911 6.676-14.911 14.911 0 .144.018.284.022.428a27.144 27.144 0 00-4.768-.428c-14.934 0-27.04 12.106-27.04 27.04 0 8.379 3.808 15.861 9.792 20.819-.003-.189-.01-.374-.01-.564z"
          fill="#fbc773"
        />
        <path
          d="M381.441 460.864l-18.735-26.445h-230.48l-14.096 26.445 3.56 28.49a9.76 9.76 0 009.68 8.55h237.18a9.76 9.76 0 009.68-8.55l3.56-28.45z"
          fill="#e43e54"
        />
        <path
          d="M170.009 147.549a7.477 7.477 0 01-5.289-2.182c-7.955-7.911-12.335-18.446-12.335-29.666 0-23.07 18.769-41.839 41.839-41.839a7.5 7.5 0 010 15c-14.799 0-26.839 12.04-26.839 26.839 0 7.197 2.81 13.955 7.913 19.03a7.5 7.5 0 01.029 10.606 7.474 7.474 0 01-5.318 2.212z"
          fill="#fbce85"
        />
        <path
          d="M416.901 148.934c-19.55-16.13-41.92-17.8-68.68 3.07h-32.846v308.86h66.066l.35.04 38.07-304.58c.35-2.81-.78-5.59-2.96-7.39z"
          fill="#f9f7f8"
        />
        <path
          d="M281.161 152.004h-32.627v308.86h75.047l24.64-308.86c-22.88-20.05-45.26-21.06-67.06 0z"
          fill="#e43e54"
        />
        <path
          d="M151.701 152.004c-26.76-20.87-49.13-19.2-68.68-3.07a8.265 8.265 0 00-2.96 7.39l38.07 304.54h58.21v-302.98z"
          fill="#f9f7f8"
        />
        <path
          d="M218.761 152.004c-21.8-21.06-44.18-20.05-67.06 0l24.64 308.86h67.372v-308.86z"
          fill="#e43e54"
        />
        <path
          d="M274.531 460.864l6.63-308.86c-22.23-19.02-43.26-22.1-62.4 0l6.63 308.86z"
          fill="#f9f7f8"
        />
        <g fill="#efedef">
          <path d="M381.441 460.864l.35.04.005-.04zM127.946 148.934c2.985-2.463 6.036-4.584 9.158-6.344-20.451-10.322-38.198-6.763-54.084 6.344a8.265 8.265 0 00-2.96 7.39l38.07 304.54h44.925l-38.07-304.54a8.269 8.269 0 012.961-7.39z" />
        </g>
        <g>
          <g fill="#efedef">
            <path d="M281.24 151.929l.08.074c.28-.324.562-.624.843-.936-.307.288-.615.566-.923.862zM249.09 136.603a30.563 30.563 0 00-10.754 1.421c3.598-.992 7.183-1.468 10.754-1.421zM381.441 460.864l.35.04.005-.04z" />
          </g>
        </g>
        <g>
          <path
            d="M260.457 82.666a7.5 7.5 0 01-7.5-7.5c0-5.065-4.121-9.186-9.186-9.186a7.5 7.5 0 010-15c13.336 0 24.186 10.85 24.186 24.186a7.5 7.5 0 01-7.5 7.5z"
            fill="#fbce85"
          />
        </g>
        <g>
          <path
            d="M302.569 114.762a7.5 7.5 0 01-7.5-7.5c0-15.11 12.292-27.402 27.402-27.402a7.5 7.5 0 010 15c-6.839 0-12.402 5.563-12.402 12.402a7.5 7.5 0 01-7.5 7.5z"
            fill="#fbce85"
          />
        </g>
        <path
          d="M498.344 319.553c0-9.802-7.946-17.748-17.748-17.748-7.482 0-13.87 4.636-16.481 11.188a17.656 17.656 0 00-7.26-1.564c-9.802 0-17.748 7.946-17.748 17.748s7.946 17.748 17.748 17.748c1.737 0 3.411-.261 4.998-.726 1.463 8.327 8.715 14.661 17.462 14.661 9.802 0 17.748-7.946 17.748-17.748 0-4.183-1.456-8.021-3.877-11.054a17.69 17.69 0 005.158-12.505z"
          fill="#fcd89f"
        />
        <path
          d="M494.824 348.904c-8.747 0-15.999-6.333-17.462-14.661a17.737 17.737 0 01-4.998.726c-9.802 0-17.748-7.946-17.748-17.748 0-2.007.349-3.929.963-5.728-9.203.657-16.471 8.313-16.471 17.683 0 9.802 7.946 17.748 17.748 17.748 1.737 0 3.411-.261 4.998-.726 1.463 8.328 8.715 14.661 17.462 14.661 7.795 0 14.4-5.032 16.785-12.02-.424.031-.846.065-1.277.065z"
          fill="#fbce85"
        />
        <path
          d="M52.133 212.803c-3.848-9.015-14.275-13.204-23.29-9.357-6.882 2.937-10.937 9.709-10.766 16.759a17.665 17.665 0 00-7.291 1.411c-9.015 3.848-13.204 14.275-9.357 23.29 3.848 9.015 14.275 13.204 23.29 9.357a17.723 17.723 0 004.312-2.63c4.615 7.085 13.771 10.063 21.815 6.63 9.015-3.848 13.204-14.275 9.357-23.29a17.663 17.663 0 00-7.905-8.645 17.69 17.69 0 00-.165-13.525z"
          fill="#fcd89f"
        />
        <path
          d="M15.172 254.962c-3.848-9.015.341-19.443 9.357-23.29a17.665 17.665 0 017.291-1.411c-.171-7.05 3.885-13.822 10.766-16.759a17.645 17.645 0 019.275-1.257c-4.037-8.62-14.195-12.564-23.018-8.799-6.882 2.937-10.937 9.709-10.766 16.759a17.665 17.665 0 00-7.291 1.411c-9.015 3.848-13.204 14.275-9.357 23.29 2.552 5.981 8.004 9.818 14.015 10.613-.088-.187-.19-.365-.272-.557z"
          fill="#fbce85"
        />
      </svg>
    </div>
  );
};

export default CarouselLoader;
