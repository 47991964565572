import React from "react";
import LoginApp from "./login/LoginApp"
import './login/LoginApp.css';
import './login/style.css';

export default function JoinUs() {
  return (
    <>
      <LoginApp/>
    </>
  );
}
